.FileCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.2);
    background-size: 45px 58px;
    border: 1px solid #E1E1E1;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    aspect-ratio: 3/2;
    padding: 0 6px;
}

.loaderDiv {
    aspect-ratio: 3/2;
}

.FileCard:hover {
    background-color: rgba(233, 232, 232, 0.2);
}

.FileCard img {
    width: 100%;
    object-fit: contain;
    height: 50px;
}

.FileCard img.pdf {
    padding: 4px;
}

.FileCard>div p {
    text-align: center;
    margin: 10px 0 0 0;
    margin-top: 8px;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-break: anywhere;
    font-size: 12px;
    width: 90%;
    line-height: 12px;
}

.FileCard .FileSize {
    display: block;
    text-align: center;
    color: rgb(132, 134, 134);
    font-size: 14px;
}

.FileInfo {
    width: 100%;
}

/* HTML: <div class="loader"></div> */
.GetFileloader {
    width: 90px;
    height: 14px;
    box-shadow: 0 3px 0 #fff;
    position: relative;
    clip-path: inset(-40px 0 -5px)
}

.GetFileloader:before {
    content: "";
    position: absolute;
    inset: auto calc(50% - 17px) 0;
    height: 50px;
    --g: no-repeat linear-gradient(#ccc 0 0);
    background: var(--g), var(--g), var(--g), var(--g);
    background-size: 16px 14px;
    animation:
        l7-1 2s infinite linear,
        l7-2 2s infinite linear;
}

@keyframes l7-1 {

    0%,
    100% {
        background-position: 0 -50px, 100% -50px
    }

    17.5% {
        background-position: 0 100%, 100% -50px, 0 -50px, 100% -50px
    }

    35% {
        background-position: 0 100%, 100% 100%, 0 -50px, 100% -50px
    }

    52.5% {
        background-position: 0 100%, 100% 100%, 0 calc(100% - 16px), 100% -50px
    }

    70%,
    98% {
        background-position: 0 100%, 100% 100%, 0 calc(100% - 16px), 100% calc(100% - 16px)
    }
}

@keyframes l7-2 {
    0%, 70% {
        transform: translate(0)
    }

    100% {
        transform: translate(200%)
    }
}


.border.border-radius {
    border: 1px solid #E1E1E1;
    border-radius: 6px;
}